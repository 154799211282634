import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import ExpandIcon from '../../../images/product-assets/plus-circle.svg';
import CloseIcon from '../../../images/product-assets/minus-circle.svg';

const Details = styled.details`
    border-top: 1px solid #e8e8e8;
    padding-top: 24px;
    margin-top: 32px;
    @media screen and (max-width: 500px) {
        margin-top: 24px;
    }

    & summary::-webkit-details-marker {
        display: none;
    }

    &:first-child {
        border: none;
        padding-top: 0;
        margin-top: 0;
    }
`;

const Summary = styled.summary`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const QueHeading = styled.h3`
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #333;

    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
`;

const Text = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #52525b;
    margin-top: 8px;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0.3')};
    max-height: ${({ isOpen, contentHeight }) => (isOpen ? `${contentHeight}px` : '0')};
    transition: all 0.5s ease-out;
`;

const Img = styled.img`
    width: 24px;
    height: 24px;
    padding: 0;
`;

const InfoLink = styled.a`
    color: #03045e;
    font-weight: 500;
`;

function Accordion({ question }) {
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        // Update content height on mount and whenever isOpen changes
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [isOpen, contentHeight]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Details open={isOpen} onToggle={toggleAccordion}>
            <Summary>
                <QueHeading>{question.title}</QueHeading>
                <Img src={isOpen ? CloseIcon : ExpandIcon} alt="Accordion Icon" />
            </Summary>
            <Text isOpen={isOpen} contentHeight={contentHeight} ref={contentRef}>
                {question.desc}{' '}
                {question?.link && (
                    <InfoLink href={question.link} target="_blank" rel="noreferrer">
                        {question.linkText}
                    </InfoLink>
                )}
            </Text>
        </Details>
    );
}

Accordion.propTypes = {
    question: PropTypes.object,
};

export default Accordion;
