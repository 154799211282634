import React from 'react';
import styled from 'styled-components';
import Map from '../../../../images/product-assets/Map_wrap.svg';

const Container = styled.section`
    background-image: linear-gradient(
        90deg,
        hsl(218deg 85% 26%) 0%,
        hsl(216deg 84% 28%) 10%,
        hsl(215deg 84% 30%) 20%,
        hsl(213deg 84% 31%) 30%,
        hsl(211deg 82% 36%) 40%,
        hsl(209deg 81% 42%) 50%,
        hsl(206deg 82% 47%) 60%,
        hsl(205deg 83% 49%) 70%,
        hsl(207deg 82% 45%) 80%,
        hsl(208deg 82% 41%) 90%,
        hsl(210deg 84% 36%) 100%
    );

    @media screen and (max-width: 580px) {
        background: #0b449d;
    }
`;

const ScheduleBox = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 54px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 44px;

    @media screen and (max-width: 580px) {
        flex-direction: column-reverse;
        justify-content: center;
        padding: 32px 16px;
    }
`;

const TextContainer = styled.div`
    width: 40%;
    @media screen and (max-width: 580px) {
        text-align: center;
        width: 100%;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 600;
    line-height: 48px;
    color: #fff;

    @media screen and (max-width: 960px) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const SubHeading = styled.p`
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #fff;
    margin-top: 24px;
    width: 70%;

    @media screen and (max-width: 960px) {
        width: 100%;
        font-size: 14px;
        line-height: 17px;
    }

    @media screen and (max-width: 580px) {
        font-size: 16px;
        line-height: 20px;
        margin-top: 16px;
        width: 100%;
        padding: 0 30px;
    }
`;

const LinkWrap = styled.a`
    text-decoration: none;
    color: #333;
    border-radius: 6px;
    padding: 12px 24px;
    display: inline-block;
    background: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 60px;

    @media screen and (max-width: 960px) {
        margin-top: 32px;
    }
`;

const ImageContainer = styled.div`
    width: 60%;

    @media screen and (max-width: 580px) {
        width: 100%;
    }
`;

const Image = styled.img`
    height: 300px;
    width: 100%;

    @media screen and (max-width: 580px) {
        height: auto;
        width: 100%;
    }
`;

function Schedule() {
    return (
        <Container id="schedule">
            <ScheduleBox>
                <TextContainer>
                    <Heading>Let’s get started!</Heading>
                    <SubHeading>
                        Complete our Get Started form to sign up and begin verifying users. See our
                        Pricing table and Billing dashboard for costs and promotional tiers.
                    </SubHeading>
                    <LinkWrap href="/#contact" secondary>
                        Schedule a Demo
                    </LinkWrap>
                </TextContainer>
                <ImageContainer>
                    <Image src={Map} alt="World map" width="auto" height="auto" />
                </ImageContainer>
            </ScheduleBox>
        </Container>
    );
}

export default Schedule;
