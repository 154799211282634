import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import Accordion from '../Accordion';

const Container = styled.section`
    background: #fff;

    @media screen and (max-width: 600px) {
        padding: 0px 10px;
    }
`;

const FaqContainer = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 80px 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 100px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        padding: 64px 16px;
        gap: 32px;
    }
`;

const Heading = styled.h1`
    color: ${({ color }) => color || '#03045e'};
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;

    @media screen and (max-width: 600px) {
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        padding: 0 10px;
    }

    @media screen and (max-width: 1024px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    ${({ reauthenticate }) =>
        reauthenticate &&
        css`
            font-size: 42px;
        `};
`;

const QuestionsLists = styled.div`
    flex: 2 2;
`;

const HeadingWrapper = styled.div`
    flex: 1 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SubHeading = styled.p`
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #6b7280;
    margin-top: 20px;

    @media screen and (max-width: 1024px) {
        margin-top: 10px;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
`;

function ProductFaq({ faqData, titleColor, subHeading, reauthenticate }) {
    return (
        <Container id="FAQ">
            <FaqContainer>
                <HeadingWrapper>
                    <Heading color={titleColor} reauthenticate={reauthenticate}>
                        Frequently Asked Questions
                    </Heading>
                    {subHeading && <SubHeading>{subHeading}</SubHeading>}
                </HeadingWrapper>
                <QuestionsLists>
                    {faqData?.map((question) => (
                        <Accordion question={question} key={question.id} />
                    ))}
                </QuestionsLists>
            </FaqContainer>
        </Container>
    );
}

ProductFaq.propTypes = {
    faqData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
        })
    ),
    titleColor: PropTypes.string,
    subHeading: PropTypes.string,
    reauthenticate: PropTypes.bool,
};

export default ProductFaq;
