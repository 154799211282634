import React from 'react';
import Schedule from '../../sections/Product/components/common/Schedule';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import { backgroundVerificationFaq } from '../../sections/Product/BackgroundVerification/constant';
import Resources from '../../sections/Product/BackgroundVerification/Resources';
import VerificationCosts from '../../sections/Product/BackgroundVerification/VerificationCosts';
import UseCase from '../../sections/Product/BackgroundVerification/UseCase';
import Advantages from '../../sections/Product/BackgroundVerification/Advantages';
import Solutions from '../../sections/Product/BackgroundVerification/Solutions';
import HeroSection from '../../sections/Product/BackgroundVerification/HeroSection';
import ProductSeo from '../../components/ProductSeo';
import RootLayout from '../../layouts/RootLayout';

function BackgroundCheck() {
    return (
        <>
            <ProductSeo
                title="Background Verifications"
                description="Instantly verify employment, education, professional licenses, and more with Authenticate®’s Background Verifications to enhance trust and security."
                ogImage="https://cdn.authenticating.com/public/website/OG-BG-CHECK.jpg"
            />
            <RootLayout>
                <HeroSection />
                <Solutions />
                <Advantages />
                <UseCase />
                <VerificationCosts />
                <Resources />
                <ProductFaq
                    faqData={backgroundVerificationFaq}
                    subHeading="Everything you need to know about Background Verifications."
                />
                <Schedule />
            </RootLayout>
        </>
    );
}

export default BackgroundCheck;
